import { ICurrency } from '@/types/api/swap/currency.interface'
import { SwapAssetModel } from '../types'
import { IWalletBalance } from '@/providers/balance/types'

export const parseAssets = (assets: ICurrency[], balances: IWalletBalance[]): SwapAssetModel[] => {
  const assetsForSwap = assets.filter((asset: ICurrency) => asset.supportSwap)

  return assetsForSwap.map((asset: ICurrency) => {
    const balance = balances.find(
      (balance: IWalletBalance) => balance.currency.id === asset._id
    )?.balance

    return {
      name: asset.title,
      network: asset.blockchainName,
      currency: asset.name,
      balance: balance?.value || '0',
      usdBalance: balance?.usd.toString() || '0',
      id: asset._id
    }
  })
}
