import { IReducerAction } from '@/types/other/state.interface'
import { IWalletBalance } from '../balance/types'
import { ISwapEsimateItem } from '@/types/api/swap/estimate.interface'

export type SwapAssetModel = {
  name: string
  network: string
  currency: string
  balance: string
  usdBalance: string
  id: string
}

export type SwapAccountModel = {
  name: string
  avatar: string
  address: string
  currency: string
  balance: string
  usdBalance: string
}

export type SwapOfferModel = {
  name: string
  trustScore: number
  supportRate: number
  kycRisk: ESwapOfferKYCRisk
  currency: string
  amount: string
  offerType: ESwapOfferType
  eta: string
  logoUrl: string
}

export enum ESwapOfferKYCRisk {
  rare = 'rare',
  medium = 'medium',
  low = 'low',
  required = 'required'
}

export enum ESwapOfferType {
  fixed = 'Fixed',
  floating = 'Floating'
}

export enum ESwapTransactionStatus {
  failure = 'failure',
  pending = 'pending',
  received = 'received'
}

export type SwapTransactionModel = {
  fromCurrency: string
  toCurrency: string
  toAccount: string
  fromAccount: string
  networkFee: string
  networkName: string
  networkSymbol: string
  hash: string
  date: string
  status: ESwapTransactionStatus
  partner: string
  exchangeRate: string
  sentAmount: string
  receivedAmount: string
  confirmationBlock: string
}

export interface SwapState {
  assets: SwapAssetModel[]
  assetForSend: SwapAssetModel | null
  assetForReceive: SwapAssetModel | null
  sendAmount: string | null
  estimates: ISwapEsimateItem[]
  offer: SwapOfferModel | null
}

export interface SwapEstimateParams {
  fromId: string
  toId: string
  amount: string
  sort: string
}

export interface ISwapContext {
  state: SwapState
  getAssets: (balances: IWalletBalance[]) => Promise<any>
  setAssetForSend: (asset: SwapAssetModel) => void
  setAssetForReceive: (asset: SwapAssetModel) => void
  replaceAssets: () => void
  setSendAmount: (value: string) => void
  estimateSwap: () => Promise<any>,
  setOffer: (offer: SwapOfferModel) => void
}

export enum EActions {
  GET_ASSETS = 'GET_ASSETS',
  SET_ASSET_FOR_SEND = 'SET_ASSET_FOR_SEND',
  SET_ASSET_FOR_RECEIVE = 'SET_ASSET_FOR_RECEIVE',
  SET_SEND_AMOUNT = 'SET_SEND_AMOUNT',
  SET_ESTIMATES = 'SET_ESTIMATES',
  SET_OFFER = 'SET_OFFER'
}

export enum ESwapActionType {
  SEND = 'SEND',
  RECEIVE = 'RECEIVE'
}

export interface IAssetActionGet extends IReducerAction<EActions.GET_ASSETS> {
  assets: SwapAssetModel[]
}

export interface IAssetActionSetAssetForSend extends IReducerAction<EActions.SET_ASSET_FOR_SEND> {
  asset: SwapAssetModel
}

export interface IAssetActionSetAssetForReceive
  extends IReducerAction<EActions.SET_ASSET_FOR_RECEIVE> {
  asset: SwapAssetModel
}

export interface IActionSetAmountForSend extends IReducerAction<EActions.SET_SEND_AMOUNT> {
  sendAmount: string
}

export interface IActionSetEstimates extends IReducerAction<EActions.SET_ESTIMATES> {
  estimates: ISwapEsimateItem[]
}

export interface IActionSetOffer extends IReducerAction<EActions.SET_OFFER> {
  offer: SwapOfferModel
}

export type Actions =
  | IAssetActionGet
  | IAssetActionSetAssetForSend
  | IAssetActionSetAssetForReceive
  | IActionSetAmountForSend
  | IActionSetEstimates
  | IActionSetOffer
